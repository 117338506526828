import { useQuery } from '@tanstack/react-query';
import { useApiClient } from 'components/ApiClientProvider';
import { PUICardSimple, PUIForm } from 'shared-components';
import { PUIAutocomplete } from 'shared-components/PUIAutocomplete';
import { PUIButton } from 'shared-components/PUIButton';
import PUIDatePicker from 'shared-components/PUIDatePicker/PUIDatePicker';
import { PUIGrid, PUIGridItem } from 'shared-components/PUIGrid';
import {
  convertObjectToSelectOptions,
  convertArrayToSelectOptions,
  getDefaultSearchParams,
  EquipmentInfo,
  getEquipmentSubOptions,
  SearchParams,
  SearchFormAutocompleteOption,
} from './search';
import { useTheme } from '@emotion/react';
import { useForm, Controller } from 'react-hook-form';
import { useFetchErrorMessage } from 'components/useFetchErrorMessage';
import { mergeWith } from 'lodash';

const STALE_TIME = Infinity;

const useGeoSuggestions = (inputText: string) => {
  const { gatewayClient } = useApiClient();
  const { data: options, isLoading } = useQuery(
    ['geo-suggestions', inputText],
    async ({ queryKey }) => {
      const prefixParam = queryKey[1];
      if (gatewayClient !== null && prefixParam.length > 0) {
        const response =
          await gatewayClient.operations.geo_suggestions_v1_geo_suggestions_get(
            [{ name: 'prefix', value: prefixParam, in: 'query' }]
          );

        return response.data;
      }
      return [];
    },
    {
      staleTime: STALE_TIME,
    }
  );

  return {
    options: options ?? [],
    isLoading,
  };
};

export const isCurrentDate = (date: Date) => {
  let dateStr;
  try {
    dateStr = date.toISOString().split('T')[0];
  } catch (error: unknown) {
    if (error instanceof RangeError) {
      return false;
    }
  }
  const currentDateStr = new Date().toISOString().split('T')[0];
  return dateStr === currentDateStr;
};

export const mapFormValueToOptions = (
  allOptions?: SearchFormAutocompleteOption[],
  formValue?: string[]
): SearchFormAutocompleteOption[] => {
  if (!formValue) return [];
  return allOptions?.filter((val) => formValue.includes(val.value)) ?? [];
};

export function mergeInitialFormWithDefaultParams(
  initialForm: SearchParams | undefined,
  defaultParameters: SearchParams
) {
  if (typeof initialForm === 'undefined') return defaultParameters;
  return mergeWith<SearchParams, SearchParams>(
    initialForm,
    defaultParameters,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    (objVal, srcVal) => (objVal == null ? srcVal : objVal)
  );
}

export interface SearchFormProps {
  onSearch?: (searchParams: SearchParams) => void;
  initialForm?: SearchParams;
}

export const SearchForm = ({ onSearch, initialForm }: SearchFormProps) => {
  const theme = useTheme();
  const { gatewayClient } = useApiClient();

  const defaultSearchParams = mergeInitialFormWithDefaultParams(
    initialForm,
    getDefaultSearchParams()
  );

  const { control, handleSubmit, getValues, watch, formState, setValue } =
    useForm<SearchParams>({
      // TODO: should we remove watching on "equipmentType" field or change the validation mode to onSubmit or onBlur?
      mode: 'onChange',
      defaultValues: defaultSearchParams,
    });

  console.log('form values', getValues());

  const resetEquipmentSubtypes = () => {
    setValue('equipmentSubtype', []);
    setValue('equipmentAccessorials', []);
    setValue('equipmentExtras', []);
  };

  const onSubmit = (data: SearchParams) => onSearch && onSearch(data);
  const onSubmitHandler = handleSubmit(onSubmit);

  const handleFetchError = useFetchErrorMessage();

  const equipmentType = watch('equipmentType') ?? '';
  const { data: equipmentInfo, isLoading: isEquipmentLoading } = useQuery(
    ['equipment-info'],
    async () => {
      const response =
        await gatewayClient?.operations?.get_equipment_v1_equipment_get();
      // FIXME: please fix type declarations here (use actual types from the client model)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return response?.data as unknown as EquipmentInfo;
    },
    {
      staleTime: STALE_TIME,
      onError: (error: Error) => {
        handleFetchError(error, 'Equipment Information');
      },
    }
  );

  const { data: loadExtras } = useQuery(
    ['load-extras'],
    async () => {
      const response =
        await gatewayClient?.operations?.get_load_extras_v1_load_extras_get();
      return response?.data;
    },
    {
      staleTime: STALE_TIME,
      onError: (error: Error) => {
        handleFetchError(error, 'Load Extras');
      },
    }
  );

  const equipmentTypeOptions =
    equipmentInfo && convertArrayToSelectOptions(Object.keys(equipmentInfo));
  const equipmentSubtypeOptions = getEquipmentSubOptions(
    equipmentInfo,
    equipmentType,
    'SUBTYPES'
  );
  const equipmentAccessorialOptions = getEquipmentSubOptions(
    equipmentInfo,
    equipmentType,
    'ACCESSORIALS'
  );
  const loadExtrasOptions =
    loadExtras && convertObjectToSelectOptions({ ...loadExtras });

  return (
    <PUIForm>
      <PUICardSimple size="large" className="my3">
        <PUIGrid spacing={8} columns={12}>
          <PUIGridItem md={4}>
            <Controller
              name="pickup"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <PUIAutocomplete
                  label="Pickup *"
                  useOptionsHook={useGeoSuggestions}
                  multiple={false}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </PUIGridItem>

          <PUIGridItem md={4}>
            <Controller
              name="delivery"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <PUIAutocomplete
                  label="Delivery *"
                  useOptionsHook={useGeoSuggestions}
                  multiple={false}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </PUIGridItem>

          <PUIGridItem md={4}>
            <Controller
              name="date"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => {
                console.log('datepicker value', value);
                return (
                  <PUIDatePicker
                    label="Date *"
                    onChange={onChange}
                    value={value}
                    fullWidth
                    disablePast={true}
                    addonEnd={value && isCurrentDate(value) && 'Today'}
                  />
                );
              }}
            />
          </PUIGridItem>
        </PUIGrid>
      </PUICardSimple>

      {/* Equipment */}
      <PUICardSimple size="large" className="my3">
        <PUIGrid spacing={8} columns={12}>
          <PUIGridItem md={4}>
            <Controller
              name="equipmentType"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => {
                return (
                  <PUIAutocomplete
                    label="Equipment *"
                    multiple={false}
                    useOptionsHook={() => ({
                      options: equipmentTypeOptions ?? [],
                      isLoading: isEquipmentLoading,
                    })}
                    onChange={(option) => {
                      onChange(option?.value);
                      if (option?.value !== value) {
                        resetEquipmentSubtypes();
                      }
                    }}
                    value={value ? { value } : null}
                  />
                );
              }}
            />
          </PUIGridItem>
          <PUIGridItem md={4}>
            <Controller
              name="equipmentSubtype"
              control={control}
              rules={{ required: false }}
              render={({ field: { onChange, value } }) => (
                <PUIAutocomplete
                  label="Subtype"
                  multiple={true}
                  limitTags={4}
                  disabled={equipmentSubtypeOptions?.length === 0}
                  useOptionsHook={() => ({
                    options: equipmentSubtypeOptions ?? [],
                    isLoading: isEquipmentLoading,
                  })}
                  onChange={(options) => onChange(options?.map((o) => o.value))}
                  value={mapFormValueToOptions(equipmentSubtypeOptions, value)}
                />
              )}
            />
          </PUIGridItem>
          <PUIGridItem md={4}>
            <Controller
              name="equipmentAccessorials"
              control={control}
              rules={{ required: false }}
              render={({ field: { onChange, value } }) => (
                <PUIAutocomplete
                  label="Accessorials"
                  multiple={true}
                  limitTags={4}
                  disabled={equipmentAccessorialOptions?.length === 0}
                  useOptionsHook={() => ({
                    options: equipmentAccessorialOptions ?? [],
                    isLoading: isEquipmentLoading,
                  })}
                  onChange={(options) => onChange(options?.map((o) => o.value))}
                  value={mapFormValueToOptions(
                    equipmentAccessorialOptions,
                    value
                  )}
                />
              )}
            />
          </PUIGridItem>
          <PUIGridItem md={4}>
            <Controller
              name="equipmentExtras"
              control={control}
              rules={{ required: false }}
              render={({ field: { onChange, value } }) => (
                <PUIAutocomplete
                  label="Extras"
                  multiple={true}
                  limitTags={4}
                  disabled={loadExtrasOptions?.length === 0}
                  useOptionsHook={() => ({
                    options: loadExtrasOptions ?? [],
                    isLoading: isEquipmentLoading,
                  })}
                  onChange={(options) => onChange(options?.map((o) => o.value))}
                  value={mapFormValueToOptions(loadExtrasOptions, value)}
                />
              )}
            />
          </PUIGridItem>
        </PUIGrid>
      </PUICardSimple>

      <div
        css={{
          marginTop: theme.spacing(4),
        }}
      >
        <PUIGrid justifyContent="center">
          <PUIGridItem md={3}>
            <PUIButton
              type="submit"
              fullWidth
              variant="contained"
              disabled={!formState.isValid}
              onClick={(e) => void onSubmitHandler(e)}
            >
              SEARCH
            </PUIButton>
          </PUIGridItem>
        </PUIGrid>
      </div>
    </PUIForm>
  );
};
