import { AxiosError } from 'axios';
import { useApiClient } from 'components/ApiClientProvider';
import { isAxiosAuthorizationError, useToasters } from 'shared-components';

type ErrorData = {
  detail: { message: string };
};

const FetchErrorMessage = ({
  error,
  errorType,
}: {
  error: Error | AxiosError<ErrorData>;
  errorType: string;
}) => {
  let errorMessage = <div>{String(error)}</div>;

  if (error instanceof AxiosError) {
    if (error.response?.data?.detail && error.response.data.detail.message) {
      errorMessage = <>{error.response.data.detail.message}</>;
    } else {
      errorMessage = (
        <>
          <div>{`Could not load data for "${errorType}" due to the error: ${error.message}.`}</div>
          <div>{`Please refresh the page and try again.`}</div>
          <div>{`Reach out to our Customer Service if the error persists`}</div>
        </>
      );
    }
  }

  return errorMessage;
};

// TODO: call handleFetchError globally for all queries from the FetchClientProvider.tsx (see Jira issue PEP-691)
export const useFetchErrorMessage = () => {
  const { enqueueToaster } = useToasters();
  const { session } = useApiClient();

  return function handleFetchError(error: Error, erroredModuleName: string) {
    if (isAxiosAuthorizationError(error)) {
      // TODO: this type of error should not be logged as a real error
      session.logout(true);
      return;
    }
    enqueueToaster(
      'error',
      <FetchErrorMessage error={error} errorType={erroredModuleName} />
    );
    console.error(
      `Could not load data for "${erroredModuleName}" due to the error: ${error.message}`,
      error
    );
  };
};
